import { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { styled, shouldForwardProp } from '@mui/system';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { StyledMenuItem } from '../styled';
import { IAutocompleteComponentProps } from '../types';
import { ReactComponent as CaretDown } from '../MySelect/CaretDown.svg';
import styles from '../FormCheckbox/FormCheckbox.module.scss';

interface IStyledTextFieldProps {
  fillColor?: string;
  customWidth?: string | number;
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    shouldForwardProp(prop) && prop !== 'fillColor' && prop !== 'customWidth',
})<IStyledTextFieldProps>(({ fillColor, customWidth }) => ({
  width: customWidth || '100%',
  '& .MuiInputBase-input': {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '16px',
    width: '100%',
    height: '16px',
    lineHeight: '16px',
    color: '#2A3842',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    backgroundColor: fillColor || '#ffffff',
    color: '#2A3842',
  },
  '& .MuiAutocomplete-tag': {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '16px',
    backgroundColor: '#F1F7FF',
    borderRadius: '2px',
    marginRight: '40px',
    color: '#2A3842',
  },
  '& .MuiChip-deleteIcon': {
    color: '#2A3842',
    position: 'absolute',
    right: '-30px',
    zIndex: 2,
  },
}));

const AutocompleteComponent: FC<IAutocompleteComponentProps> = ({
  options,
  numOfSelected,
  placeholder,
  customWidth,
  name,
  control,
  multiple = false,
  error,
  helperText,
  disabled,
  changeAutocompleteValue,
  changeNewAutocompleteValue,
  dataQa,
}) => {
  const [isInOptions, setIsInOptions] = useState(true);
  const [open, setOpen] = useState(false);

  return (
    <Controller
      shouldUnregister={false}
      name={name}
      control={control}
      defaultValue={multiple ? [] : ''}
      render={({ field }) => (
        <Autocomplete
          data-qa={dataQa}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          //clearIcon={<ClearIcon style={{ fontFamily: '"Poppins", sans-serif', fontSize: '2rem', }} />}
          clearIcon={null}
          multiple={multiple}
          id='checkboxes-tags'
          options={options}
          disabled={disabled}
          freeSolo
          disableCloseOnSelect={multiple && name !== 'creator'}
          value={multiple ? field.value || [] : field.value || ''}
          getOptionLabel={(option) => option}
          ChipProps={{
            deleteIcon: <CloseOutlinedIcon fontSize='medium' />,
          }}
          onInputChange={(event, newInputValue) => {
            if (
              newInputValue &&
              changeAutocompleteValue &&
              (name === 'profileVerificationOptions' ||
                name === 'company' ||
                name === 'regionOfOperation' ||
                name === 'listingLocations' ||
                name === 'requestLocations')
            ) {
              changeAutocompleteValue(newInputValue);
            }
          }}
          onChange={(event, newValue) => {
            if (multiple) {
              if (typeof numOfSelected === 'undefined' || newValue.length <= numOfSelected) {
                field.onChange(newValue);
                if (name === 'creator') {
                  changeNewAutocompleteValue?.(newValue[0]);
                }
              }
            } else {
              field.onChange(newValue || '');
              setIsInOptions(options.includes(newValue));
              if (newValue && changeNewAutocompleteValue) {
                changeNewAutocompleteValue(newValue);
              }
            }
          }}
          renderOption={(props, option, { selected }) => (
            <StyledMenuItem {...props} key={option}>
              {multiple ? (
                <Checkbox
                  icon={<span />}
                  checkedIcon={<span className={styles.checked} />}
                  style={{ transform: 'scale(0.65)' }}
                  checked={selected}
                />
              ) : null}
              {option}
            </StyledMenuItem>
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              variant='outlined'
              placeholder={
                multiple
                  ? field.value && field.value.length
                    ? ''
                    : placeholder
                  : field.value
                    ? ''
                    : placeholder
              }
              fullWidth
              customWidth={customWidth}
              error={error}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <div
                      style={{
                        position: 'absolute',
                        cursor: 'pointer',
                        pointerEvents: 'none',
                        right: 15,
                        height: '20px',
                        width: '20px',
                        transform: open ? 'rotate(180deg)' : 'none',
                      }}
                    >
                      <CaretDown />
                    </div>
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteComponent;
