import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  schemaSignUpBrokerAndAgency,
  schemaSignUpManagerWithEmail,
  schemaSignUpManagerWithPhone,
  schemaSignUpForeignBrokerPersonWithEmail,
  schemaSignUpForeignBrokerPersonWithPhone,
  schemaSignUpForeignBrokerOrganizationWithEmail,
  schemaSignUpForeignBrokerOrganizationWithPhone,
  schemaConsultationOperator,
  schemaEditProfileBrokerAndManager,
  schemaLogInWithEmail,
  schemaLogInWithPhone,
  schemaResetPasswordWithEmail,
  schemaResetPasswordWithPhone,
  schemaCreatePassword,
  schemaChangeLoginSettings,
  schemaNotificationsWhatsapp,
} from '@/schemas';
import { formOfOrganization, language } from './fakeData';
import { ProfilePropertyKindOldEnum, ProfilePropertyKindNewEnum, UserTariffPlanEnum } from '@/types';

export const useSignUpForm = (user, kind, valueFormOfOrganization, dialogData) => {
  const currentSchema = () => {
    const isCheckedEmail =
      dialogData &&
      dialogData?.dialogContent?.find((item) => item.name === 'emailOrPhone')?.initialVisibility;
    switch (kind) {
      case 'manager':
        return isCheckedEmail ? schemaSignUpManagerWithEmail : schemaSignUpManagerWithPhone;
      case 'foreign':
        if (valueFormOfOrganization === 'physical') {
          return isCheckedEmail
            ? schemaSignUpForeignBrokerPersonWithEmail
            : schemaSignUpForeignBrokerPersonWithPhone;
        } else {
          return isCheckedEmail
            ? schemaSignUpForeignBrokerOrganizationWithEmail
            : schemaSignUpForeignBrokerOrganizationWithPhone;
        }
      default:
        return schemaSignUpBrokerAndAgency;
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      formOfOrganization: '',
      country: '',
      city: '',
      profileVerificationOptions: '',
      companyName: '',
      name: '',
      company: '',
      emailOrPhone: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      officeNumber: '',
      social: '',
      other: '',
    },
    resolver: yupResolver(currentSchema()),
  });

  const resetForm = (user) => {
    reset({
      formOfOrganization: '',
      country: '',
      city: '',
      social: '',
      other: '',
      profileVerificationOptions: user ? user.profileVerificationOptions : '',
      companyName: user ? (kind === 'broker' ? user.company : user.name) : '',
      name: user ? user.name : '',
      company: user ? user.company : '',
      emailOrPhone: user ? user.email : '',
      email: user ? user.email : '',
      phone: user ? user.phone : '',
      password: '',
      confirmPassword: '',
      phoneNumber: user ? user.phone : '',
      officeNumber: user ? user.officeNumber : '',
    });
  };

  return {
    register,
    handleSubmit,
    errors,
    control,
    resetForm,
    setValue,
  };
};

export const useOperatorForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      emailOrPhone: '',
      phoneNumber: '',
      description: '',
    },
    resolver: yupResolver(schemaConsultationOperator),
  });

  const resetForm = () => {
    reset({
      name: '',
      emailOrPhone: '',
      phoneNumber: '',
      description: '',
    });
  };

  return {
    register,
    handleSubmit,
    errors,
    control,
    resetForm,
    setValue,
  };
};

export const useEditInfoForm = (user, userLicenseData) => {
  const invertEnum = (enumObj) =>
    Object.entries(enumObj).reduce((obj, [key, value]) => {
      obj[value] = key;
      return obj;
    }, {});
  const invertedOldEnum = invertEnum(ProfilePropertyKindOldEnum);
  const invertedNewEnum = invertEnum(ProfilePropertyKindNewEnum);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  let gender;
  let specialization;
  let propertyType;
  let personKind;

  if (user?.gender) {
    gender = capitalizeFirstLetter(user?.gender);
  }
  if (user?.activityKinds) {
    specialization = user?.activityKinds?.map(capitalizeFirstLetter);
  }
  if (user?.propertyKinds) {
    const isOldPropertyType = user.propertyKinds.some(
      (kind) => kind === 'building' || kind === 'house' || kind === 'land' || kind === 'commercial'
    );
    propertyType = user.propertyKinds.map((value) =>
      isOldPropertyType ? invertedOldEnum[value] : invertedNewEnum[value]
    );
  }
  if (user?.personKind) {
    personKind = formOfOrganization.find((el) => el.name === user?.personKind)?.label;
  }
  const languageLabels = user.languages.map((langCode) => {
    const languageObj = language.find((lang) => lang.name === langCode);
    return languageObj ? languageObj.label : langCode;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      licenseEmail: userLicenseData?.email || '',
      nameInPublicRegister: userLicenseData?.nameEn || '',
      stateIdentityIdentifier: userLicenseData?.stateId || '',
      licensePhone: userLicenseData?.phone || '',
      stateLicenseExpirationDate: userLicenseData?.licenseEnd || '',
      stateLicenseAgency: userLicenseData?.agency?.stateId || userLicenseData?.licenseId || '',
      userName: user.name || '',
      formOfOrganization: personKind || '',
      gender: gender || '',
      nationality: user.nationality || '',
      language: languageLabels || '',
      description: user.description || '',

      email: user.additionalEmails[0] || userLicenseData?.email || user.email || '',
      phone: user.additionalPhones?.[0] || userLicenseData?.phone || user.phone || '',
      phoneNumberSecond: user.additionalPhones?.[1] || '',
      whatsapp: user.whatsapp || '',
      telegram: user.telegram || '',
      instagram: user.instagram || '',
      linkedin: user.linkedin || '',
      address: user.address || '',
      additionalLinks: user.links?.[0] || '',
      additionalLinksSecond: user.links?.[1] || '',
      additionalLinksThird: user.links?.[2] || '',

      specialization: specialization || [],
      propertyType: propertyType || [],
      regionOfOperation: user.operationRegions.map((region) => region.name) || [],
    },
    resolver: yupResolver(schemaEditProfileBrokerAndManager),
  });

  return {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
  };
};

export const useLogInForm = (dialogData) => {
  const currentSchema = () => {
    if (dialogData.dialogContent.find((item) => item.name === 'emailOrPhone').initialVisibility) {
      return schemaLogInWithEmail;
    }
    return schemaLogInWithPhone;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      emailOrPhone: '',
      password: '',
      phoneNumber: '',
    },
    resolver: yupResolver(currentSchema()),
  });

  const resetForm = () => {
    reset({
      emailOrPhone: null,
      password: null,
      phoneNumber: null,
    });
  };

  return {
    register,
    handleSubmit,
    errors,
    control,
    resetForm,
    setValue,
  };
};

export const useResetPasswordForm = (dialogData) => {
  const currentSchema = () => {
    if (dialogData.dialogContent.find((item) => item.name === 'emailOrPhone').initialVisibility) {
      return schemaResetPasswordWithEmail;
    }
    return schemaResetPasswordWithPhone;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      emailOrPhone: '',
    },
    resolver: yupResolver(currentSchema()),
  });

  const resetForm = () => {
    reset({
      emailOrPhone: '',
    });
  };

  return {
    register,
    handleSubmit,
    errors,
    control,
    resetForm,
    setValue,
  };
};

export const useCreatePasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schemaCreatePassword),
  });

  const resetForm = () => {
    reset({
      password: '',
      confirmPassword: '',
    });
  };

  return {
    register,
    handleSubmit,
    errors,
    control,
    resetForm,
  };
};

export const useChangeLoginForm = (userSettings) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset
  } = useForm({
    defaultValues: {
      email: userSettings.email || '',
      phone: userSettings.phone || '',
    },
    resolver: yupResolver(schemaChangeLoginSettings),
  });

  useEffect(() => {
    reset({
      email: userSettings.email || '',
      phone: userSettings.phone || '',
    });
  }, [userSettings, reset]);

  const resetForm = () => {
    reset({
      email: userSettings.email || '',
      phone: userSettings.phone || '',
    });
  };

  return {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    resetForm,
  };
};

export const useSettingsForm = (user, userSettings) => {
  const isTrial = user.statusSubscription === 'trialing';
  const calculateDaysRemaining = (trialEndDate) => {
    const currentDate = new Date();
    const trialEnd = new Date(trialEndDate);
    const timeDifference = trialEnd.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  };
  const trialPeriod = user?.trialEnd && calculateDaysRemaining(user?.trialEnd);
  const tariff = user?.tariffPlan?.name || '';
  const listingLocationsName = userSettings.listingNotificationsWhatsapp.locations?.map((location) => location.name);
  const requestLocationsName = userSettings.requestNotificationsWhatsapp.locations?.map((location) => location.name);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      passwordSettings: '',
      verificationStatus: '',
      tariff: tariff,
      trialPeriod: isTrial ? `${tariff}. Trial period: ${trialPeriod} days left.` : null,
      email: userSettings.email || '',
      phone: userSettings.phone || '',
      currency: userSettings.currency || '',
      areaUnits: userSettings.areaUnits || '',
      sendCommentEmails: userSettings.sendCommentEmails ?? true,
      sendMessageEmails: userSettings.sendMessageEmails ?? true,
      sendClientRequestEmails: userSettings.sendClientRequestEmails ?? true,
      sendTimersEmails: userSettings.sendTimersEmails ?? true,
      sendCometchatEmails: userSettings.sendCometchatEmails ?? true,
      sendFriendRequestEmails: userSettings.sendFriendRequestEmails ?? true,

      listingLocations: listingLocationsName ?? [],
      listingTypeOfActivity: userSettings.listingNotificationsWhatsapp.typeOfActivity ?? [],
      listingPropertyType: userSettings.listingNotificationsWhatsapp.propertyType ?? [],
      listingBeds: userSettings.listingNotificationsWhatsapp.beds ?? [],
      listingPriceFrom: userSettings.listingNotificationsWhatsapp.priceFrom ?? 0,
      listingPriceTo: userSettings.listingNotificationsWhatsapp.priceTo ?? 0,
      listingSizeFrom: userSettings.listingNotificationsWhatsapp.sizeFrom ?? 0,
      listingSizeTo: userSettings.listingNotificationsWhatsapp.sizeTo ?? 0,
      
      requestLocations: requestLocationsName ?? [],
      requestTypeOfActivity: userSettings.requestNotificationsWhatsapp.typeOfActivity ?? [],
      requestPropertyType: userSettings.requestNotificationsWhatsapp.propertyType ?? [],
      requestBeds: userSettings.requestNotificationsWhatsapp.beds ?? [],
      requestPriceFrom: userSettings.requestNotificationsWhatsapp.priceFrom ?? 0,
      requestPriceTo: userSettings.requestNotificationsWhatsapp.priceTo ?? 0,
      //requestSizeFrom: userSettings.requestNotificationsWhatsapp.sizeFrom ?? 0,
      //requestSizeTo: userSettings.requestNotificationsWhatsapp.sizeTo ?? 0,
    },
    resolver: yupResolver(schemaNotificationsWhatsapp),
  });

  useEffect(() => {
    reset({
      tariff: tariff,
      trialPeriod: isTrial ? `${tariff}. Trial period: ${trialPeriod} days left.` : null,
      email: userSettings.email || '',
      phone: userSettings.phone || '',
      currency: userSettings.currency || '',
      areaUnits: userSettings.areaUnits || '',
      sendCommentEmails: userSettings.sendCommentEmails ?? true,
      sendMessageEmails: userSettings.sendMessageEmails ?? true,
      sendClientRequestEmails: userSettings.sendClientRequestEmails ?? true,
      sendTimersEmails: userSettings.sendTimersEmails ?? true,
      sendCometchatEmails: userSettings.sendCometchatEmails ?? true,
      sendFriendRequestEmails: userSettings.sendFriendRequestEmails ?? true,
      listingLocations: listingLocationsName ?? [],
      listingTypeOfActivity: userSettings.listingNotificationsWhatsapp.typeOfActivity ?? [],
      listingPropertyType: userSettings.listingNotificationsWhatsapp.propertyType ?? [],
      listingBeds: userSettings.listingNotificationsWhatsapp.beds ?? [],
      listingPriceFrom: userSettings.listingNotificationsWhatsapp.priceFrom ?? 0,
      listingPriceTo: userSettings.listingNotificationsWhatsapp.priceTo ?? 0,
      listingSizeFrom: userSettings.listingNotificationsWhatsapp.sizeFrom ?? 0,
      listingSizeTo: userSettings.listingNotificationsWhatsapp.sizeTo ?? 0,
      requestLocations: requestLocationsName ?? [],
      requestTypeOfActivity: userSettings.requestNotificationsWhatsapp.typeOfActivity ?? [],
      requestPropertyType: userSettings.requestNotificationsWhatsapp.propertyType ?? [],
      requestBeds: userSettings.requestNotificationsWhatsapp.beds ?? [],
      requestPriceFrom: userSettings.requestNotificationsWhatsapp.priceFrom ?? 0,
      requestPriceTo: userSettings.requestNotificationsWhatsapp.priceTo ?? 0,
      //requestSizeFrom: userSettings.requestNotificationsWhatsapp.sizeFrom ?? 0,
      //requestSizeTo: userSettings.requestNotificationsWhatsapp.sizeTo ?? 0,
    });
  }, [user, userSettings.email, userSettings.phone, userSettings.currency, userSettings.areaUnits, reset]);

  return {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
    watch,
  };
};

export const useAgencyLicenseForm = (userLicenseData) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      id: userLicenseData?.id || '',
      email: userLicenseData?.email || '',
      licenseEnd: userLicenseData?.licenseEnd || '',
      licenseId: userLicenseData?.licenseId || '',
      licenseStart: userLicenseData?.licenseStart || '',
      manual: userLicenseData?.manual || false,
      nameEn: userLicenseData?.nameEn || '',
      phone: userLicenseData?.phone || '',
      stateId: userLicenseData?.stateId || '',
    },
  });

  return {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
  };
};
