import { FC } from 'react';
import { Stack } from '@mui/material';
import { LabelComponent } from '@/components';
import AutocompleteComponent from './AutocompleteComponent';
import { IMyAutocompleteProps } from '../types';

export const MyAutocomplete: FC<IMyAutocompleteProps> = ({
  data,
  control,
  disabled,
  errors,
  changeAutocompleteValue,
  changeNewAutocompleteValue,
}) => {
  const {
    name,
    options,
    numOfSelected,
    placeholder,
    label,
    isRequired,
    iconInfo,
    tooltip,
    customWidth,
    multiple,
    dataQa,
  } = data;

  return (
    <Stack sx={{ width: '100%' }}>
      <LabelComponent label={label} isRequired={isRequired} iconInfo={iconInfo} tooltip={tooltip} />
      <AutocompleteComponent
        dataQa={dataQa}
        name={name}
        control={control}
        options={options}
        numOfSelected={numOfSelected}
        placeholder={placeholder}
        customWidth={customWidth}
        multiple={multiple}
        error={!!errors?.[name]}
        helperText={errors?.[name]?.message}
        disabled={disabled}
        changeAutocompleteValue={changeAutocompleteValue}
        changeNewAutocompleteValue={changeNewAutocompleteValue}
      />
    </Stack>
  );
};
