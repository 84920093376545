import { useState, useEffect } from 'react';
import { Control } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { MyAutocomplete } from '@/components';
import { appSetNotification } from '@/store';
import { usersProvider } from '@/providers';
import { IErrors } from '../../../../../uiComponents/types';
import { NotificationTypeEnum } from '@/types';
import styles from './Locations.module.scss';

export const Locations = ({
  errors,
  control,
  type,
}: {
  errors: IErrors;
  control: Control;
  type: 'listings' | 'requests';
}) => {
  const [inputAutocompleteValue, setAutocompleteValue] = useState('');
  const [areas, setAreas] = useState<string[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleOnChangeAutocomplete(inputAutocompleteValue);
  }, [inputAutocompleteValue]);

  const handleOnChangeAutocomplete = async (param: string) => {
    if (!param) {
      setAreas([]);
      return;
    }
    try {
      const { ok, data, status, message } = await usersProvider.getOperationRegion({
        query: param,
      });
      if (!ok || !data) {
        dispatch(
          appSetNotification(NotificationTypeEnum.Error, message || 'Unknown error', status)
        );
        setAreas([]);
        return;
      }
      if (typeof data === 'object' && 'items' in data && Array.isArray(data.items)) {
        const options = data.items.map((item) => item.name);
        setAreas(options);
      } else {
        setAreas([]);
      }
    } catch (error) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, 'An error occurred', 500));
      setAreas([]);
    }
  };

  return (
    <div>
      <div className={styles.container}>
          <div className={styles.myAutocomplete}>
            <MyAutocomplete
              errors={errors}
              control={control}
              changeAutocompleteValue={setAutocompleteValue}
              data={{
                name: type === 'listings' ? 'listingLocations' : 'requestLocations',
                multiple: true,
                options: areas,
                placeholder: 'Choose location',
              }}
            />
          </div>
        </div>
    </div>
  );
};
