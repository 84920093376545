import queryString from 'query-string';
import {
  AuthResetPasswordRequestType,
  AuthChangeLoginRequestType,
  OperationRegion,
  ResponseType,
  UserModel,
  UserSettingsResponseType,
  TeamGetAllMembersResponseType,
  PaginationType,
  AdsGetAllResponseType,
  ClientRequestGetAllResponseType,
  SubscriptionOrderConfirmation,
  CreateSubscription,
} from '@/types';
import { apiClient } from '@/providers';

interface ICommonParams {
  query: string;
  limit?: number;
  offset?: number;
}
interface TotalCountsType {
  properties: number;
  team: number;
  request: number;
}

interface IGetCreatorsByNameParams {
  name: string;
  limit?: number;
  offset?: number;
}

export const usersProvider = {
  getMyUser: async () => {
    const response = await apiClient<UserModel>('/user/me/profile', 'GET');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  getUserById: async (userId: string) => {
    const response = await apiClient<UserModel>(`/user/${userId}/profile`, 'GET');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  getUserSettings: async () => {
    const response = await apiClient<UserSettingsResponseType>(`/user/me/settings`, 'GET');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  getCreatorsByName: async (payload: IGetCreatorsByNameParams) => {
    const { name, limit = 5, offset = 0 } = payload;
    const response = await apiClient<UserSettingsResponseType>(`/propertyAd/creators`, 'POST', { name, limit, offset });
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  patchUserSettings: async ({
    currency,
    areaUnits,
    sendCommentEmails,
    sendMessageEmails,
    sendClientRequestEmails,
    sendTimersEmails,
    sendCometchatEmails,
    sendFriendRequestEmails,
    listingNotificationsWhatsapp,
    requestNotificationsWhatsapp,
  }: UserSettingsResponseType): Promise<ResponseType> => {
    const response = await apiClient(`/user/me/settings`, 'PATCH', {
      currency,
      areaUnits,
      sendCommentEmails,
      sendMessageEmails,
      sendClientRequestEmails,
      sendTimersEmails,
      sendCometchatEmails,
      sendFriendRequestEmails,
      listingNotificationsWhatsapp,
      requestNotificationsWhatsapp,
    });
    if (!response.ok) {
      return { ...response, data: null };
    }
    return { ...response };
  },

  patchMyUser: async (payload: UserModel): Promise<ResponseType> => {
    const response = await apiClient('/user/me/profile', 'PATCH', payload);
    // const data = response.ok ? response.data as UserModel : null;

    if (!response.ok) {
      return { ...response, data: null };
    }
    return { ...response };
  },

  postChangePasswordRequest: async (): Promise<ResponseType> => {
    const response = await apiClient('/user/me/changePassword/request', 'POST');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  postChangePasswordCheck: async (payload: AuthResetPasswordRequestType): Promise<ResponseType> => {
    const response = await apiClient('/user/me/changePassword/check', 'POST', payload);
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  postChangePasswordChange: async (
    payload: AuthResetPasswordRequestType
  ): Promise<ResponseType> => {
    const response = await apiClient('/user/me/changePassword/change', 'POST', payload);
    const data = response.ok ? (response.data as UserModel) : null;
    return { ...response, data };
  },

  getOperationRegion: async (params: ICommonParams): Promise<ResponseType> => {
    const { limit = 50, offset = 0, query } = params;
    const paramsStr = queryString.stringify({ limit, offset, query });
    const response = await apiClient(`/registry/operationRegion?${paramsStr}`, 'GET');
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  getCounter: async (userId: string) => {
    const response = await apiClient<UserModel>(`/user/${userId}/counters`, 'POST');
    const data = response.ok ? response.data as TotalCountsType : null;
    return { ...response, data };
  },

  getUserMembers: async (userId: string, newPaginationObj: PaginationType): Promise<ResponseType> => {
    const response = await apiClient(`/user/${userId}/team`, 'POST', newPaginationObj);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as TeamGetAllMembersResponseType;
    return { ...response, data };
  },

  getUserAds: async (userId: string, newPaginationObj: PaginationType): Promise<ResponseType> => {
    const response = await apiClient(`/user/${userId}/properties`, 'POST', newPaginationObj);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as AdsGetAllResponseType;
    return { ...response, data };
  },

  getUserRequests: async (userId: string, newPaginationObj: PaginationType): Promise<ResponseType> => {
    const newRequestsObj =
    {
      ...newPaginationObj,
      sort: [
        {
          field: "createdAt",
          order: "ASC"
        }
      ]
    }
    const response = await apiClient(`/user/${userId}/requests`, 'POST', newRequestsObj);
    if (!response.ok) {
      return { ...response, data: null };
    }

    const data = response.data as ClientRequestGetAllResponseType;
    return { ...response, data };
  },

  getUserByChatId: async (chatId: string) => {
    const response = await apiClient<UserModel>(`/user/userId/${chatId}`, 'GET');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  postChangeLoginRequest: async (payload: AuthChangeLoginRequestType): Promise<ResponseType> => {
    const response = await apiClient('/user/me/changeLogin/request', 'POST', payload);
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  postChangeLoginChange: async (payload: AuthChangeLoginRequestType): Promise<ResponseType> => {
    const response = await apiClient('/user/me/changeLogin/change', 'POST', payload);
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  getTariffPlan: async () => {
    const response = await apiClient('/tariff-plan', 'GET');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  getSubscription: async (priceId: string) => {
    const response = await apiClient('/subscription', 'POST', { priceId: priceId });
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  createSubscription: async (payload: CreateSubscription) => {
    const response = await apiClient('/subscription/create-subscription', 'POST', payload);
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  postSubscriptionOrderConfirmation: async (payload: SubscriptionOrderConfirmation) => {
    const response = await apiClient('/subscription/order-confirmation', 'POST', payload);
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  cometchatActivity: async (id: string) => {
    const response = await apiClient<UserModel>(`/user/cometchatActivity/${id}`, 'POST');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  saveContactMethod: async (contactMethod: string) => {
    const response = await apiClient('/user/me/saveContactMethod', 'POST', { contactMethod: contactMethod });
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  getReferralsLink: async () => {
    const response = await apiClient('/referrals/details', 'GET');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  getListReferrals: async (affiliateId: string) => {
    const payload = {
      limit: 50,
      page: 0,
      affiliateId: affiliateId,
    }
    const response = await apiClient(`/referrals/listReferrals`, 'POST', payload);
    if (!response.ok) {
      return { ...response, data: null };
    }
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },

  cancelSubscription: async () => {
    const response = await apiClient('/subscription/cancel-subscription', 'GET');
    const data = response.ok ? response.data : null;
    return { ...response, data };
  },
};
