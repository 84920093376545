import React, { forwardRef } from 'react';
import styles from './SectionTitle.module.scss';

const SectionTitle = forwardRef<
  HTMLDivElement,
  {
    number?: number | string;
    required?: boolean;
    title: string;
    errorTitle?: string;
    variant?: 'blank';
  }
>(({ number, required, title, variant, errorTitle }, ref) => {
  return (
    <div
      ref={ref}
      tabIndex={-1}
      className={styles.container}
      style={variant === 'blank' ? { margin: 0 } : {}}
    >
      {!!number && (
        <div className={styles.number}>
          <span>{number}</span>
        </div>
      )}
      <div className={styles.title}>
        {title}
        {required && <span>* </span>}
        {errorTitle ? (
          <span
            className='Mui-error'
            style={{ color: 'red', fontSize: '0.5em', fontWeight: '400' }}
          >
            {errorTitle}
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
});

SectionTitle.displayName = 'SectionTitle';

export default SectionTitle;
