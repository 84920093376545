import { useEffect, useState, ChangeEvent } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Stack, Typography, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { IErrors } from '../../../../uiComponents/types';
import {
  WhatsAppActivityTypeEnum,
  WhatsAppPropertyTypeEnum,
  AdFieldCurrencyEnum,
  AdFieldBedsEnum,
} from '@/types';
import { SectionTitle, FormButtons } from '@/components';
import { Locations } from './Locations';
import styles from './WhatsAppForm.module.scss';

interface DataProps {
  beds: AdFieldBedsEnum[];
  locations: ILocations[];
  priceFrom: number;
  priceTo: number;
  sizeFrom: number;
  sizeTo: number;
  propertyType: WhatsAppPropertyTypeEnum[];
  typeOfActivity: WhatsAppActivityTypeEnum[];
}

interface WatchDataProps {
  currency: AdFieldCurrencyEnum;
  areaUnits: string;
}

interface SetValueDataProps {
  listingLocations: string[];
  listingTypeOfActivity: WhatsAppActivityTypeEnum[];
  listingPropertyType: WhatsAppPropertyTypeEnum[];
  listingBeds: AdFieldBedsEnum[];
  listingPriceFrom: number;
  listingPriceTo: number;
  listingSizeFrom: number;
  listingSizeTo: number;
  requestLocations: string[];
  requestTypeOfActivity: WhatsAppActivityTypeEnum[];
  requestPropertyType: WhatsAppPropertyTypeEnum[];
  requestBeds: AdFieldBedsEnum[];
  requestPriceFrom: number;
  requestPriceTo: number;
  // requestSizeFrom: number;
  // requestSizeTo: number;
}

interface ILocations {
  id: number;
  name: string;
}

export const WhatsAppForm = ({
  data,
  setValue,
  watch,
  errors,
  control,
  type,
}: {
  errors: IErrors;
  control: Control;
  data: DataProps;
  setValue: UseFormSetValue<SetValueDataProps>;
  watch: UseFormWatch<WatchDataProps>;
  type: 'listings' | 'requests';
}) => {
  const currency = watch('currency');
  const area = watch('areaUnits').toLowerCase();

  const [typeOfActivity, setTypeOfActivity] = useState<WhatsAppActivityTypeEnum[]>([]);
  const [propertyType, setPropertyType] = useState<WhatsAppPropertyTypeEnum[]>([]);
  const [priceFrom, setPriceFrom] = useState<string | null>();
  const [priceTo, setPriceTo] = useState<string | null>();
  const [sizeFrom, setSizeFrom] = useState<string | null>();
  const [sizeTo, setSizeTo] = useState<string | null>();
  const [beds, setBeds] = useState<AdFieldBedsEnum[]>([]);

  useEffect(() => {
    setTypeOfActivity(data.typeOfActivity || []);
    setPropertyType(data.propertyType || []);
    setPriceFrom(data.priceFrom > 0 ? data.priceFrom.toString() : null);
    setPriceTo(data.priceTo > 0 ? data.priceTo.toString() : null);
    setSizeFrom(data.sizeFrom > 0 ? data.sizeFrom.toString() : null);
    setSizeTo(data.sizeTo > 0 ? data.sizeTo.toString() : null);
    setBeds(data.beds || []);
  }, [data]);

  useEffect(() => {}, [priceFrom, priceTo]);

  const typeOfActivityValues = [
    // { label: 'Buy', value: WhatsAppActivityTypeEnum.Buy },
    { label: 'Rent', value: WhatsAppActivityTypeEnum.Rent },
    { label: 'Sell', value: WhatsAppActivityTypeEnum.Sell },
  ];

  const propertyTypeValues = [
    { label: 'Residential', value: WhatsAppPropertyTypeEnum.Residential },
    { label: 'Commercial', value: WhatsAppPropertyTypeEnum.Commercial },
  ];

  const bedsValues = [
    { label: 'Studio', value: AdFieldBedsEnum.Studio },
    { label: '1', value: AdFieldBedsEnum.One },
    { label: '2', value: AdFieldBedsEnum.Two },
    { label: '3', value: AdFieldBedsEnum.Three },
    { label: '4', value: AdFieldBedsEnum.Four },
    { label: '5', value: AdFieldBedsEnum.Five },
    { label: '6', value: AdFieldBedsEnum.Six },
    { label: '7', value: AdFieldBedsEnum.Seven },
    { label: '8+', value: AdFieldBedsEnum.EightPlus },
  ];

  const handleActivityTypeClick = (value: WhatsAppActivityTypeEnum) => {
    handleMultiSelectClick(
      typeOfActivity,
      value,
      setTypeOfActivity,
      type === 'listings' ? 'listingTypeOfActivity' : 'requestTypeOfActivity'
    );
  };

  const handlePropertyTypeClick = (value: WhatsAppPropertyTypeEnum) => {
    handleMultiSelectClick(
      propertyType,
      value,
      setPropertyType,
      type === 'listings' ? 'listingPropertyType' : 'requestPropertyType'
    );
  };

  const handleBedsClick = (value: AdFieldBedsEnum) => {
    handleMultiSelectClick(
      beds,
      value,
      setBeds,
      type === 'listings' ? 'listingBeds' : 'requestBeds'
    );
  };

  const handleMultiSelectClick = <
    T extends WhatsAppActivityTypeEnum | WhatsAppPropertyTypeEnum | AdFieldBedsEnum,
  >(
    currentValues: T[],
    value: T,
    setState: (newValues: T[]) => void,
    fieldName: keyof SetValueDataProps
  ) => {
    const newSelection = currentValues.includes(value)
      ? currentValues.filter((item) => item !== value)
      : [...currentValues, value];
    setState(newSelection);
    setValue(fieldName, newSelection);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: keyof SetValueDataProps,
    setState: (newValues: string) => void
  ) => {
    const value = e.target.value.replace(/ /g, '');
    setState(value);
    setValue(name, Number(value));
  };

  return (
    <Stack className={styles.container}>
      <SectionTitle
        number={`${type === 'listings' ? 6 : 5}`}
        title={`Manage ${type} notifications on WhatsApp`}
      />
      <Typography sx={{ fontSize: '18px', marginBottom: '15px' }}>
        <span style={{ color: '#1650FF', fontWeight: 600 }}>*</span>
        {`No more than ${type === 'listings' ? 7 : 5} ${type} per day`}
      </Typography>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography sx={{ fontSize: '15px' }}>Type of activity</Typography>
          <FormButtons
            options={typeOfActivityValues}
            onClick={handleActivityTypeClick}
            activeButton={typeOfActivity}
            size='big'
            name={type === 'listings' ? 'listingTypeOfActivity' : 'requestTypeOfActivity'}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography sx={{ fontSize: '15px' }}>Property type</Typography>
          <FormButtons
            options={propertyTypeValues}
            onClick={handlePropertyTypeClick}
            activeButton={propertyType}
            size='big'
            name={type === 'listings' ? 'listingPropertyType' : 'requestPropertyType'}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Stack spacing={1}>
            <Typography sx={{ fontSize: '15px' }}>{`Price from (${currency})`}</Typography>
            <NumericFormat
              sx={{ width: { xs: '100%', sm: '300px' } }}
              customInput={TextField}
              value={priceFrom}
              allowNegative={false}
              thousandSeparator=' '
              decimalScale={0}
              placeholder={`Enter minimum price (${currency})`}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(
                  e,
                  type === 'listings' ? 'listingPriceFrom' : 'requestPriceFrom',
                  setPriceFrom
                )
              }
            />
          </Stack>
          <Stack spacing={1}>
            <Typography sx={{ fontSize: '15px' }}>{`Price to (${currency})`}</Typography>
            <NumericFormat
              sx={{ width: { xs: '100%', sm: '300px' } }}
              customInput={TextField}
              value={priceTo}
              allowNegative={false}
              thousandSeparator=' '
              decimalScale={0}
              placeholder={`Enter maximum price (${currency})`}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(
                  e,
                  type === 'listings' ? 'listingPriceTo' : 'requestPriceTo',
                  setPriceTo
                )
              }
              error={Number(priceFrom) > Number(priceTo) && Number(priceTo) !== 0}
              helperText={
                Number(priceFrom) > Number(priceTo) && Number(priceTo) !== 0
                  ? 'max value should be more or equal than min value'
                  : ''
              }
            />
          </Stack>
        </Stack>
        {type === 'listings' && (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Stack spacing={1}>
              <Typography sx={{ fontSize: '15px' }}>{`Size from (${area})`}</Typography>
              <NumericFormat
                sx={{ width: { xs: '100%', sm: '300px' } }}
                customInput={TextField}
                value={sizeFrom}
                allowNegative={false}
                thousandSeparator=' '
                decimalScale={0}
                placeholder={`Enter minimum size (${area})`}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, 'listingSizeFrom', setSizeFrom)
                }
              />
            </Stack>
            <Stack spacing={1}>
              <Typography sx={{ fontSize: '15px' }}>{`Size to (${area})`}</Typography>
              <NumericFormat
                sx={{ width: { xs: '100%', sm: '300px' } }}
                customInput={TextField}
                value={sizeTo}
                allowNegative={false}
                thousandSeparator=' '
                decimalScale={0}
                placeholder={`Enter maximum size (${area})`}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, 'listingSizeTo', setSizeTo)
                }
                error={Number(sizeFrom) > Number(sizeTo) && Number(sizeTo) !== 0}
                helperText={
                  Number(sizeFrom) > Number(sizeTo) && Number(sizeTo) !== 0
                    ? 'max value should be more or equal than min value'
                    : ''
                }
              />
            </Stack>
          </Stack>
        )}
        <Stack spacing={1}>
          <Typography sx={{ fontSize: '15px' }}>Bedrooms</Typography>
          <FormButtons
            options={bedsValues}
            onClick={handleBedsClick}
            activeButton={beds}
            size='circle'
            name={type === 'listings' ? 'listingBeds' : 'requestBeds'}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography sx={{ fontSize: '15px' }}>Choose location</Typography>
          <Locations control={control} errors={errors} type={type} />
        </Stack>
      </Stack>
    </Stack>
  );
};
