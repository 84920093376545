import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usersProvider, supportProvider } from '@/providers';
import FormData from '../EditProfile/FormData';
import { Grid, Stack, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import Confirmation from '../../Dialogs/Confirmation';
import DialogContentRenderer from '../../Dialogs/DialogContentRenderer';
import { authenticationData, verificationData, viewSettingsData, viewSettingsManagerData, authorizationData, actions, tariffSettingsData, trialTariffData } from '../../utils/UserProfileSettingsDescription';
import { dialogConfirmationChangePasswordData, dialogCreatePasswordData, dialogSuccessData, dialogConfirmationChangeLoginData } from '../../utils/dialogDescription';
import { useSettingsForm, useCreatePasswordForm, useChangeLoginForm } from '../../utils/useFormLogic';
import { appSetNotification, authGetMyUser } from '@/store';
import { MyDialog, MyButton, Loader } from '@/components';
import { StyleLabel } from '../../../uiComponents/styled';
import { NotificationData } from '@/components/AccountManagement/Profile/Settings/NotificationData';
import { WhatsAppForm } from '@/components/AccountManagement/Profile/Settings/WhatsAppForm';
import { AuthenticationData } from './AuthenticationData';
import { UserTariffPlanEnum, WhatsAppActivityTypeEnum } from '@/types';
import styles from './Settings.module.scss';


const FormDataContainerStyle = styled(Grid)(({ theme }) => ({
  width: '700px',
  margin: '24px 10px 10px 6px',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  display: 'flex',
  padding: '24px 0',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    padding: 0,
    width: '370px',
  },
}));

const SettingsInfo = ({ userSettings, user, setIsLoginChanged, isLoginChanged }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTrial = user.statusSubscription === 'trialing';

  const [code, setCode] = useState('');
  const [userDataSubmit, setUserDataSubmit] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [textError, setTextError] = useState('');
  const [openDialog, setOpenDialog] = useState({
    changeLogin: false,
    confirmationPassword: false,
    confirmationLogin: false,
    changePassword: false,
    success: false,
    error: false,
    warning: false,
    info: false,
  });

  const handleOpenDialog = (dialogType) => {
    setOpenDialog(prevState => ({
      ...prevState,
      [dialogType]: !prevState[dialogType]
    }));
  };

  const navigate = useNavigate();

  const { handleSubmit, errors, control, watch, setValue } = useSettingsForm(user, userSettings);

  useEffect(() => {
    const firstErrorElement = Object.keys(errors)[0];
    if (firstErrorElement) {
      console.error('Form errors:', errors);
      const elementForFocus = document.getElementsByClassName('Mui-error')[0];
      if (elementForFocus) {
        const offset =
          elementForFocus.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          150;
        window.scrollTo({
          behavior: 'smooth',
          top: offset,
        });
      }
    }
  }, [errors]);

  const {
    handleSubmit: handleSubmitCreatePassword,
    errors: errorsCreatePassword,
    control: controlCreatePassword,
    resetForm: resetCreatePasswordForm
  } = useCreatePasswordForm();

  const {
    handleSubmit: handleSubmitChangeLoginForm,
    errors: errorsChangeLoginForm,
    control: controlChangeLoginForm,
    setValue: setValueChangeLoginForm,
    resetForm: resetChangeLoginForm,
  } = useChangeLoginForm(userSettings);

  const onSubmit = async (data) => {
    const getOperationRegionData = async (region) => {
      const response = await usersProvider.getOperationRegion({ query: region });
      return response.data.items[0];
    };
    const fetchOperationRegions = async (regions) => {
      const operationRegionsData = await Promise.all(regions.map(region => getOperationRegionData(region)));
      return operationRegionsData;
    };
    const listingLocations = await fetchOperationRegions(data.listingLocations);
    const requestLocations = await fetchOperationRegions(data.requestLocations);

    const dataSubmit = {
      currency: data.currency || null,
      areaUnits: data.areaUnits || null,
      sendCommentEmails: data.sendCommentEmails,
      sendMessageEmails: data.sendMessageEmails,
      sendClientRequestEmails: data.sendClientRequestEmails,
      sendTimersEmails: data.sendTimersEmails,
      sendCometchatEmails: data.sendCometchatEmails,
      sendFriendRequestEmails: data.sendFriendRequestEmails,
      listingNotificationsWhatsapp: {
        locations: listingLocations  || [],
        typeOfActivity: data.listingTypeOfActivity || [],
        propertyType: data.listingPropertyType || [],
        beds: data.listingBeds || [],
        priceFrom: data.listingPriceFrom || 0,
        priceTo: data.listingPriceTo || 0,
        sizeFrom: data.listingSizeFrom || 0,
        sizeTo: data.listingSizeTo || 0,
      },
      requestNotificationsWhatsapp: {
        locations: requestLocations  || [],
        typeOfActivity: data.requestTypeOfActivity || [],
        propertyType: data.requestPropertyType || [],
        beds: data.requestBeds || [],
        priceFrom: data.requestPriceFrom || 0,
        priceTo: data.requestPriceTo || 0,
        // sizeFrom: data.requestSizeFrom || 0,
        // sizeTo: data.requestSizeTo || 0,
      },
    };
    console.log(dataSubmit);
    if (dataSubmit) {
      const { ok, message, status } = await usersProvider.patchUserSettings(dataSubmit);
      if (ok) {
        navigate('/');
      } else {
        dispatch(appSetNotification('error', message, status));
      }
    }
  }

  const openConfirmationDialog = async () => {
    const { ok, message, status } = await usersProvider.postChangePasswordRequest();
    if (ok) {
      handleOpenDialog('confirmationPassword');
    } else {
      status === 404 ? dispatch(appSetNotification('error', message, status)) : setOpenError(true);
      setTextError('You will be able to request a new code in 2 minutes');
    }
  };

  const openAuthorizationConfirmationDialog = async () => {
    handleOpenDialog('changeLogin')
  };

  const onSubmitChangePassword = async (data) => {
    const dataSubmit = {
      code: code || null,
      password: data.password || null
    }
    const { ok, message, status } = await usersProvider.postChangePasswordChange(dataSubmit);
    if (ok) {
      handleOpenDialog('changePassword');
      handleOpenDialog('success');
    } else {
      dispatch(appSetNotification('error', message, status));
    }
  };

  const onChangeLoginClose = () => {
    handleOpenDialog('changeLogin');
    resetChangeLoginForm();
  };

  const onSubmitChangeLogin = async (data) => {
    setUserDataSubmit(data);
    const { ok, message, status } = await usersProvider.postChangeLoginRequest(data);
    if (ok) {
      onChangeLoginClose();
      handleOpenDialog('confirmationLogin');
    } else {
      status === 404 ? dispatch(appSetNotification('error', message, status)) : setOpenError(true);
      setTextError(message);
    }
  };

  const onErrorClose = () => {
    setOpenError(false);
    setTextError('');
  };

  const handleCancelTariff = () => {
    const isCanceledTariff = user?.tariffPlan?.name !== UserTariffPlanEnum.Free && user?.tariffPlan?.name !== UserTariffPlanEnum.Trial;
    if (!isCanceledTariff) {
      setOpenError(true);
      setTextError('You cannot cancel this plan');
    } else {
      setOpenCancelDialog(true);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleCancelTrialPeriod = async () => {
    const { ok, message, status } = await usersProvider.cancelSubscription();
      navigate('/');
      await delay(1000);
      const { ok: getUserOk, data: getUserData } = await usersProvider.getMyUser();
      if (!getUserOk) {
        dispatch(appSetNotification(NotificationTypeEnum.Error, 'Failed to get user data', 500));
        return;
      }
      dispatch(authGetMyUser(getUserData));
  };

  const handleConfirmTariff = async () => {
    const dataSubmit = {
      name: user.name || '',
      email: user.email || null,
      phone: user.phone || null,
      text: 'The user, contacts above, wants to cancel the subscription! Contact and clarify the reason for the cancellation and convince!',
    };
    if (dataSubmit) {
      setIsLoading(true);
      const { ok, message, status } = await supportProvider.support(dataSubmit);
      if (ok) {
        setOpenCancelDialog(false);
        setOpenDialogSuccess(true);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <FormDataContainerStyle container item xs={6}>
        <form style={{ width: isMobile ? '370px' : '700px' }} onSubmit={handleSubmit(onSubmit)}>
          <Stack alignItems='flex-start' spacing={5} sx={{ width: '100%' }}>
            <FormData control={control} formData={authorizationData} formErrors={errors} openConfirmationDialog={openAuthorizationConfirmationDialog} type='settings' isMobile={isMobile} />
            <FormData control={control} formData={authenticationData} formErrors={errors} openConfirmationDialog={openConfirmationDialog} type='settings' isMobile={isMobile} />
            {/* {user?.kind === 'manager' && <FormData control={control} formData={verificationData} formErrors={errors}/>} */}
            {/* <FormData control={control} formData={user?.kind === 'manager' ? viewSettingsManagerData : viewSettingsData} formErrors={errors}/> */}
            <FormData control={control} formData={viewSettingsData} formErrors={errors} type='settings' isMobile={isMobile} />
            {isTrial ? (
              <FormData control={control} formData={trialTariffData} formErrors={errors} type='settings' isMobile={isMobile} user={user} handleCancelTariff={handleCancelTrialPeriod} />
            ) : (
              <FormData control={control} formData={tariffSettingsData} formErrors={errors} type='settings' isMobile={isMobile} user={user} handleCancelTariff={handleCancelTariff} />
            )}
            <NotificationData control={control} />
            <WhatsAppForm control={control} formErrors={errors} data={userSettings.requestNotificationsWhatsapp} setValue={setValue} watch={watch} type='requests'/>
            <WhatsAppForm control={control} formErrors={errors} data={userSettings.listingNotificationsWhatsapp} setValue={setValue} watch={watch} type='listings'/>
            <FormData control={control} formData={actions} onSubmit={handleSubmit(onSubmit)} type='settings' isMobile={isMobile} />
          </Stack>
        </form>
      </FormDataContainerStyle>
      <Confirmation
        open={openDialog.confirmationPassword}
        onClose={() => handleOpenDialog('confirmationPassword')}
        handleOpenDialog={handleOpenDialog}
        dialogData={dialogConfirmationChangePasswordData}
        type='userResetPassword'
        setCode={setCode}
      />
      <Confirmation
        open={openDialog.confirmationLogin}
        onClose={() => {
          handleOpenDialog('confirmationLogin');
          setIsLoginChanged(!isLoginChanged)
        }}
        handleOpenDialog={handleOpenDialog}
        dialogData={dialogConfirmationChangeLoginData}
        type='userChangeLogin'
        setCode={setCode}
        userDataSubmit={userDataSubmit}
        isLoginChanged={isLoginChanged}
        setIsLoginChanged={setIsLoginChanged}
      />
      <DialogContentRenderer
        open={openDialog.changePassword}
        onClose={() => handleOpenDialog('changePassword')}
        control={controlCreatePassword}
        onSubmit={handleSubmitCreatePassword(onSubmitChangePassword)}
        dialogData={dialogCreatePasswordData}
        errors={errorsCreatePassword}
      />
      <DialogContentRenderer
        open={openDialog.success}
        handleOpenDialog={handleOpenDialog}
        dialogData={dialogSuccessData}
      />
      <MyDialog
        open={openError}
        onClose={onErrorClose}
        dialogTitle='Error'
        width='500'
      >
        <StyleLabel style={{ textAlign: 'center', color: 'red' }}>
          {textError}
        </StyleLabel>
      </MyDialog>
      <MyDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        dialogTitle='Are you sure you want to cancel your subscription?'
        width='750'
      >
        <StyleLabel style={{ textAlign: 'center' }}>
          You are about to cancel your subscription on the platform. In case of cancellation, some of the functions included in the subscription package will become unavailable to you. To fully use the platform, you will need to restore your subscription.
        </StyleLabel>
        <div className={styles.dialogButtons}>
          <MyButton
            data={{
              buttonName: 'Confirm',
              customWidth: '226px',
              variant: 'contained',
              buttonType: 'submit',
            }}
            onClick={() => handleConfirmTariff()}
          />
          <MyButton
            data={{
              buttonName: 'Cancel',
              customWidth: '226px',
              variant: 'outlined',
              buttonType: 'button',
              styleType: 'cancel',
            }}
            onClick={() => setOpenCancelDialog(false)}
          />
        </div>
      </MyDialog>
      <MyDialog 
        open={openDialogSuccess} 
        onClose={() => setOpenDialogSuccess(false)} 
        dialogTitle='Last step'
        width='650'
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Stack
            direction='row'
            justifyContent='center'
            display='flex'
          >
            <img 
              src='/Img/Pictures/operatorSuccess.svg'
              style={{width: '230px'}}
            />
          </Stack>
          <StyleLabel style={{ textAlign: 'center' }}>
            Our support manager will contact you for cancelling your subscription plan!
          </StyleLabel>
          
          <MyButton
            data={{ 
              buttonName: 'Okay',
              variant: 'contained',
              customWidth: '358px',
              actionKey: 'closeOperatorSuccessAction',
              styleType: 'dialog',
            }}
            onClick={() => setOpenDialogSuccess(false)}
          />
        </Stack>
      </MyDialog>
      <AuthenticationData
        control={controlChangeLoginForm}
        errors={errorsChangeLoginForm}
        setValue={setValueChangeLoginForm}
        onSubmit={handleSubmitChangeLoginForm(onSubmitChangeLogin)}
        open={openDialog.changeLogin}
        onClose={onChangeLoginClose}
      />
    </>
  );
};

export default SettingsInfo;
